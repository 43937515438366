<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Grupos {{table}}</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormGrupos></FormGrupos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormGrupos from "@/views/grupos/FormGrupos.vue";
import grupoService from "@/services/grupo.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateGrupos",
  components: {FormGrupos, LayoutPage, ButtonWidget},
  data(){
    return {table:null}
  },
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        tipo: this.table,
        ativo: document.getElementById('ativo').value,
      }
      let gruposService = new grupoService();
      let response = await gruposService.store(dataForm);

      if (response.data?.id) {
        location.href = '/grupos/'+this.table+'/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    initForm(){
      this.table = this.$route.params.table
    }
  },
  mounted() {
    this.initForm()
  }
}
</script>
<style scoped>
</style>
