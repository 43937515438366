<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Unidades</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormTabelaPrecosUnidades></FormTabelaPrecosUnidades>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormTabelaPrecosUnidades from "@/views/tabela_precos_unidades/FormTabelaPrecosUnidades.vue";
import tabela_precos_unidadeService from "@/services/tabela_precos_unidade.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateTabelaPrecosUnidades",
  components: {FormTabelaPrecosUnidades, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        unidade_id: document.getElementById('unidade_id').value,
        tabela_preco_id: this.$route.params.id


      }
      let tabela_precos_unidadesService = new tabela_precos_unidadeService();
      let response = await tabela_precos_unidadesService.store(dataForm);

      if (response.data?.id) {
        location.href = '/tabela_precos_unidades/index/'+this.$route.params.id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
