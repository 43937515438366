<template>
    <layout-page>
        <div class="row">
            <div class="col-12 ps-4 pt-3 ">
                <div class="float-start">
                    <h5>Adicionar Relatorio para Unidades</h5>
                </div>
                <div class="float-end">
                    <button-widget cor="azul" href="./index" tamanho="M">
                        Voltar
                    </button-widget>
                </div>
            </div>

        </div>
        <div class="row">
            <FormRelatorioUnidades></FormRelatorioUnidades>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormRelatorioUnidades from "@/views/relatorio_unidades/FormRelatorioUnidades.vue";
import relatorio_unidadeService from "@/services/relatorio_unidade.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateRelatorioUnidades",
    components: {FormRelatorioUnidades, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        unidade_id: document.getElementById('unidade_id').value,
              relatorio_id: document.getElementById('relatorio_id').value,


        }
            let relatorio_unidadesService = new relatorio_unidadeService();
            let response = await relatorio_unidadesService.store(dataForm);

            if(response.data?.id){
                location.href = '/relatorio_unidades/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>
