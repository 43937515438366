<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Tabela de Preços</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormTabelaPrecos></FormTabelaPrecos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormTabelaPrecos from "@/views/tabela_precos/FormTabelaPrecos.vue";
import tabela_precoService from "@/services/tabela_preco.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateTabelaPrecos",
  components: {FormTabelaPrecos, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        ativo: document.getElementById('ativo').value,
        tipo: document.getElementById('tipo').value,



      }
      let tabela_precosService = new tabela_precoService();
      let response = await tabela_precosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/tabela_precos/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
