<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="string" label="E-mail " value="" name="email"/>
  <input-form class-list="col-12" type="mask" mask="00.000.000/0000-00" label="CNPJ " value="" name="cnpj"/>
  <input-form class-list="col-12" type="mask" mask="000.000.000-00" label="CPF " value="" name="cpf"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select"
              :items="[{id:'cliente',message:'cliente',},{id:'fornecedor',message:'fornecedor',},]" label="Tipo "
              value="" name="tipo"/>
  <input-form class-list="col-12" type="string" label="Telefone Principal  " value="" name="telefone_principal"/>
  <input-form class-list="col-12" type="string" label="Telefone Secundário  " value="" name="telefone_secundario"/>
  <input-form class-list="col-12" type="string" label="E-mail Principal  " value="" name="email_principal"/>
  <input-form class-list="col-12" type="string" label="E-mail Secundário  " value="" name="email_secundario"/>

  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
  <div class="revendedor_none">
    <input-form placeholder="Selecione Grupo " class-list="col-12" type="select2" url="/api/grupos/list/pessoas" label="Grupo "
                value="" name="grupo_id"/>
    <input-form placeholder="Selecione Categoria " class-list="col-12" type="select2" url="/api/categorias/list/pessoas"
                label="Categoria " value="" name="categoria_id"/>
  </div>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormPessoas",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
