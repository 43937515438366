<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar ContaPagamentos</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'/conta_pagamentos/index/'+conta_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormContaPagamentos></FormContaPagamentos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormContaPagamentos from "@/views/conta_pagamentos/FormContaPagamentos.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import conta_pagamentoService from "@/services/conta_pagamento.service";

export default {
  name: "EditContaPagamentos",
  components: {LayoutPage, ButtonWidget, FormContaPagamentos},
  data(){
    return {
      conta_id:null
    }
  },
  methods: {
    async edit(id) {
      let conta_pagamentosService = new conta_pagamentoService();
      let response = await conta_pagamentosService.view(id);
      document.getElementById('valor').value = response.data.valor;
      document.getElementById('tipo').value = response.data.tipo;
      this.conta_id = response.data.conta_id;
      document.getElementById('vencimento').value = response.data.vencimento;
      document.getElementById('payment_status').value = response.data.payment_status;


    },
    async sendForm() {
      let dataForm = {
        valor: document.getElementById('valor').value,
        tipo: document.getElementById('tipo').value,
        vencimento: document.getElementById('vencimento').value,
        payment_status: document.getElementById('payment_status').value,
        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let conta_pagamentosService = new conta_pagamentoService();
      let response = await conta_pagamentosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
