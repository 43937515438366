<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Sql  " value="" name="sql"/>
  <div id="suggestions"></div>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormRelatorios",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {
    initForm() {
      const suggestions = {
        tables: [
          'anexos',
          'categorias',
          'cidades',
          'comissaos',
          'conta_movimentos',
          'conta_pagamentos',
          'contas',
          'ecommerce_produtos',
          'ecommerces',
          'enderecos',
          'eventos',
          'grupos',
          'movimentos',
          'pessoas',
          'produto_unidades',
          'produtos',
          'relatorio_unidades',
          'relatorios',
          'tabela_precos',
          'tabela_precos_produtos',
          'tabela_precos_unidades',
          'unidades',
          'users'
        ],
        keywords: ['SELECT', 'INSERT', 'UPDATE', 'DELETE', 'FROM', 'WHERE', 'JOIN', 'ORDER BY', 'GROUP BY']
      };

      const sqlInput = document.getElementById('sql');
      const suggestionsDiv = document.getElementById('suggestions');

      let selectedIndex = -1; // Índice da sugestão selecionada

      sqlInput.addEventListener('input', () => {
        const inputText = sqlInput.value.trim();
        const words = inputText.split(/\s+/);
        const lastWord = words[words.length - 1].toLowerCase();
        const matchedSuggestions = [];

        // Verifica por tabelas
        suggestions.tables.forEach(table => {
          if (table.startsWith(lastWord)) {
            matchedSuggestions.push(table);
          }
        });

        // Verifica por palavras-chave
        suggestions.keywords.forEach(keyword => {
          if (keyword.toLowerCase().startsWith(lastWord)) {
            matchedSuggestions.push(keyword);
          }
        });

        // Atualiza a lista de sugestões
        showSuggestions(matchedSuggestions);
      });

      function showSuggestions(matched) {
        suggestionsDiv.innerHTML = '';
        selectedIndex = -1; // Reseta o índice selecionado
        if (matched.length > 0) {
          matched.forEach((s, index) => {
            const div = document.createElement('div');
            div.className = 'suggestion';
            div.textContent = s;
            div.onclick = () => selectSuggestion(s);
            div.dataset.index = index; // Armazena o índice
            suggestionsDiv.appendChild(div);
          });
          suggestionsDiv.style.display = 'block';
        } else {
          suggestionsDiv.style.display = 'none';
        }
      }

      function selectSuggestion(suggestion) {
        const inputText = sqlInput.value.trim();
        const words = inputText.split(/\s+/);
        words.pop(); // Remove a última palavra
        sqlInput.value = words.join(' ') + ' ' + suggestion + ' ';
        suggestionsDiv.style.display = 'none';
        sqlInput.focus();
      }

      // Navegação por teclado
      sqlInput.addEventListener('keydown', (event) => {
        const suggestionsList = document.querySelectorAll('.suggestion');
        const totalSuggestions = suggestionsList.length;

        if (event.key === 'ArrowDown') {
          selectedIndex = (selectedIndex + 1) % totalSuggestions; // Move para baixo
          updateSelection(suggestionsList);
          event.preventDefault(); // Impede o scroll da página
        } else if (event.key === 'ArrowUp') {
          selectedIndex = (selectedIndex - 1 + totalSuggestions) % totalSuggestions; // Move para cima
          updateSelection(suggestionsList);
          event.preventDefault(); // Impede o scroll da página
        } else if (event.key === 'Enter') {
          if (selectedIndex >= 0) {
            event.preventDefault(); // Impede o Enter de adicionar nova linha
            const selectedSuggestion = suggestionsList[selectedIndex].textContent;
            selectSuggestion(selectedSuggestion);
          }
        }
      });

      function updateSelection(suggestionsList) {
        suggestionsList.forEach(s => s.classList.remove('selected'));
        if (selectedIndex >= 0) {
          suggestionsList[selectedIndex].classList.add('selected');
        }
      }

      // Oculta sugestões ao clicar fora
      document.addEventListener('click', (event) => {
        if (!sqlInput.contains(event.target) && !suggestionsDiv.contains(event.target)) {
          suggestionsDiv.style.display = 'none';
        }
      });
    }
  },

  mounted() {
    this.initForm();
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
body {
  font-family: Arial, sans-serif;
  padding: 20px;
}
#suggestions {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  display: none;
  position: relative;
  background: white;
  z-index: 10;
  width: 200px /* Ajusta a largura para não ultrapassar */
}
.suggestion {
  padding: 8px;
  cursor: pointer;
}
#suggestions>.suggestion:hover, #suggestions>.suggestion.selected {
  background-color: #69b0ef !important;
}
</style>
