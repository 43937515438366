<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> {{tipo==='saida'?'Vendas':'Compras' }}</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end">
              <button-widget cor="azul" :href="'/movimentos/create/'+tipo" tamanho="M">
                Adicionar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/movimentos/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                                        <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                                            <i class="bi bi-trash2-fill"></i>
                                             Apagar

                                        </span>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="'/anexos/index/movimentos/'+row.id">
                      <i class="bi bi-archive"></i>
                      Anexos
                    </router-link>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Cliente : </strong>{{ row.pessoa_unidade }}</div>
              <div class="col-12"><strong>Created User : </strong>{{ row.created_user }}</div>
              <div class="col-12"><strong>Unidade : </strong>{{ row.unidade }}</div>
              <div class="col-12"><strong>Estado : </strong>{{ row.estado }}</div>
            </td>
            <td>
              <div class="col-12"><strong>Produto : </strong>{{ row.produto }}</div>
              <div class="col-12"><strong>Quantidade
                : </strong>{{ Number(row.quantidade).toLocaleString('pt-BR', {minimumFractionDigits: 2}) }}
              </div>
              <div class="col-12"><strong>Valor : </strong> R$
                {{ Number(row.valor).toLocaleString('pt-BR', {minimumFractionDigits: 2}) }}
              </div>
              <div class="col-12"><strong>Desconto
                : </strong>{{ Number(row.desconto).toLocaleString('pt-BR', {minimumFractionDigits: 2}) }} %
              </div>
              <div class="col-12"><strong>Total : </strong> R$ {{
                  Number(row.total).toLocaleString('pt-BR', {minimumFractionDigits: 2})
                }}
              </div>
            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import movimentoService from "@/services/movimento.service";

export default {
  name: "IndexMovimentos",
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      tipo: null
    }
  },
  methods: {
    async list() {

      let movimentosService = new movimentoService();
      let dataRow = await movimentosService.list(this.search, this.tipo);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let movimentosService = new movimentoService();
      let dataRow = await movimentosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.tipo = this.$route.params.tipo;
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
