<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5>Pagamentos</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end">
              <button-widget cor="azul" :href="'/conta_pagamentos/create/'+conta_id" tamanho="M">
                Adicionar
              </button-widget>
            </div>
            <div class="float-end mx-2">
              <button-widget cor="azul"
                             :href="'/contas/index/'+tipo" tamanho="M">
                Voltar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/conta_pagamentos/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                    <router-link v-if="row.tipo === 'pix' || row.tipo === 'cartao_credito' || row.tipo === 'boleto'" class="dropdown-item" :to="'/conta_pagamentos/pay/'+row.id">
                      <i class="bi bi-credit-card-fill"></i>
                      Pagamento
                    </router-link>
                  </li>
                  <li>
                      <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                      <i class="bi bi-trash2-fill"></i>
                      Apagar

                      </span>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Valor : </strong>{{ row.valor }}</div>
              <div class="col-12"><strong>Vencimento : </strong>{{ row.vencimento }}</div>
              <div class="col-12"><strong>Tipo : </strong>{{ getPaymentMethodName(row.tipo) }}</div>
            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import conta_pagamentoService from "@/services/conta_pagamento.service";
import contaService from "@/services/conta.service";

export default {
  name: "IndexContaPagamentos",
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      conta_id: null,
      tipo: null
    }
  },
  methods: {
    async list() {

      let conta_pagamentosService = new conta_pagamentoService();
      let dataRow = await conta_pagamentosService.list(this.search, this.conta_id);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let conta_pagamentosService = new conta_pagamentoService();
      let dataRow = await conta_pagamentosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    getPaymentMethodName(method) {
      const methodNames = {
        dinheiro: 'Dinheiro',
        cartao_credito: 'Cartão de Crédito',
        cartao_debito: 'Cartão de Débito',
        transferencia_bancaria: 'Transferência Bancária',
        boleto: 'Boleto',
        pix: 'Pix',
        cheque: 'Cheque'
      };
      return methodNames[method] || method; // Retorna o nome formatado ou o próprio método se não encontrado
    }

  },
  async created() {
    this.conta_id = this.$route.params.conta_id;
    let contasService = new contaService();
    let response = await contasService.view(this.conta_id);
    this.tipo = await response.data.tipo;
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
