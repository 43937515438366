<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Unidades</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end">
              <button-widget cor="azul" href="/unidades/create" tamanho="M">
                Adicionar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/unidades/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="'/anexos/index/unidades/'+row.id">
                      <i class="bi bi-archive"></i>
                      Anexos
                    </router-link>
                  </li>
                  <li>
                      <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                        <i class="bi bi-trash2-fill"></i>
                         Apagar

                      </span>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Nome : </strong>{{row.nome}}</div>
              <div class="col-12"><strong>Código Unidade : </strong>{{ row.codigo_unidade }}</div>
              <div class="col-12"><strong>CNPJ Unidade : </strong>{{ new HelperTollBox().empty(row.cnpj_unidade)?'': new MaskTollBox('00.000.000/0000-00',row.cnpj_unidade).applyMask() }}</div>
              <div class="col-12"><strong>CPF Titular : </strong>{{ new HelperTollBox().empty(row.cpf_titular)?'': new MaskTollBox('000.000.0000-00',row.cpf_titular).applyMask() }}</div>
              <div class="col-12"><strong>Grupo: </strong>{{ row.grupo }}</div>
              <div class="col-12"><strong>Categoria : </strong>{{ row.categoria }}</div>
            </td>
            <td>
              <div class="col-12"><strong>IE Unidade : </strong>{{ row.ie_unidade }}</div>
              <div class="col-12"><strong>RG Titular : </strong>{{ row.rg_titular }}</div>
              <div class="col-12"><strong>Telefone Primário : </strong>{{ new HelperTollBox().empty(row.telefone_principal)?'': new MaskTollBox('(000) 00000-0000',row.telefone_principal).applyMask() }}</div>
              <div class="col-12"><strong>E-mail Primário : </strong>{{ row.email_principal }}</div>
              <div class="col-12"><strong>Tipo : </strong>{{ row.tipo }}</div>
              <div class="col-12"><strong>Ativo : </strong>{{ row.ativo?'Sim':'Não' }}</div>
            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import unidadeService from "@/services/unidade.service";
import HelperTollBox from "../../ToolBox/helper.toll.box";
import MaskTollBox from "../../ToolBox/mask.toll.box";

export default {
  name: "IndexUnidades",
  computed: {
    MaskTollBox() {
      return MaskTollBox
    },
    HelperTollBox() {
      return HelperTollBox
    }
  },
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      unidade_id:null
    }
  },
  methods: {
    async list() {

      let unidadesService = new unidadeService();
      let dataRow = await unidadesService.list(this.search);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let unidadesService = new unidadeService();
      let dataRow = await unidadesService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.list();

  },
  mounted() {
    this.unidade_id = this.$route.params.id;
  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
