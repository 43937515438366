<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Relatorios</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="../index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormRelatorios></FormRelatorios>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormRelatorios from "@/views/relatorios/FormRelatorios.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import relatorioService from "@/services/relatorio.service";

export default {
  name: "EditRelatorios",
  components: { LayoutPage, ButtonWidget, FormRelatorios },
  methods: {
    async edit(id) {
      let relatoriosService = new relatorioService();
      let response = await relatoriosService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('sql').value = response.data.sql;


    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        sql: document.getElementById('sql').value,
        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let relatoriosService = new relatorioService();
      let response = await relatoriosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped></style>
