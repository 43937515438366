<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar {{ tipo === 'saida' ? 'Vendas' : 'Compras' }}</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'../index/'+tipo" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormMovimentos></FormMovimentos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormMovimentos from "@/views/movimentos/FormMovimentos.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import movimentoService from "@/services/movimento.service";

export default {
  name: "EditMovimentos",
  components: {LayoutPage, ButtonWidget, FormMovimentos},
  data() {
    return {
      tipo: null
    }
  },
  methods: {
    async edit(id) {
      let movimentosService = new movimentoService();
      let response = await movimentosService.view(id);

      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('model_id').value = response.data.model_id;
      document.getElementById('estado').value = response.data.estado;
      document.getElementById('produto_id').value = response.data.produto_id;
      document.getElementById('quantidade').value = response.data.quantidade;
      document.getElementById('valor').value = response.data.valor;
      document.getElementById('desconto').value = response.data.desconto;
      this.tipo = response.data.tipo;


    },
    async sendForm() {
      let dataForm = {
        descritivo: document.getElementById('descritivo').value,
        pessoa_id: document.getElementById('model_id').value,
        estado: document.getElementById('estado').value,
        produto_id: document.getElementById('produto_id').value,
        quantidade: document.getElementById('quantidade').value,
        valor: document.getElementById('valor').value,
        desconto: document.getElementById('desconto').value,
        tipo: this.tipo,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let movimentosService = new movimentoService();
      let response = await movimentosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {
    this.edit(this.$route.params.id)

  }
}
</script>

<style scoped>

</style>
