<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="string" label="E-mail " value="" name="email"/>
  <input-form class-list="col-12" type="string" label="Senha  " value="" name="password"/>
  <input-form class-list="col-12" type="tinyint" label="É Admin.  " value="" name="e_admin"/>
  <input-form class-list="col-12" type="tinyint" label="É Venda  " value="" name="e_venda"/>
  <input-form class-list="col-12" type="tinyint" label="É Compra  " value="" name="e_compra"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
  <input-form v-if="displayInputUnidadeId" placeholder="Selecione Unidade " class-list="col-12" type="select2"
              url="/api/unidades/list"
              label="Unidade " value="" name="unidade_id"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormUsers",
  components: {InputForm},
  data() {
    return {
      displayInputUnidadeId: false
    }
  },
  methods: {},

  created() {
      if(location.pathname ==='/users/create'){
        this.displayInputUnidadeId = true
      }
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
