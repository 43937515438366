<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Endereços</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'/enderecos/'+table+'/index/'+pessoa_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormEnderecos></FormEnderecos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormEnderecos from "@/views/enderecos/FormEnderecos.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import enderecoService from "@/services/endereco.service";

export default {
  name: "EditEnderecos",
  components: {LayoutPage, ButtonWidget, FormEnderecos},
  data(){
    return {
      pessoa_id:null,
      table: null,
    }
  },
  methods: {
    async edit(id) {
      let enderecosService = new enderecoService();
      let response = await enderecosService.view(id);
      this.pessoa_id = response.data.model_id;
      this.table = response.data.tipo
      document.getElementById('logradouro').value = response.data.logradouro;
      document.getElementById('numero').value = response.data.numero;
      document.getElementById('complemento').value = response.data.complemento;
      document.getElementById('bairro').value = response.data.bairro;
      document.getElementById('cidade_id').value = response.data.cidade_id;
      document.getElementById('descritivo').value = response.data.descritivo;

    },
    async sendForm() {
      let dataForm = {
        logradouro: document.getElementById('logradouro').value,
        numero: document.getElementById('numero').value,
        complemento: document.getElementById('complemento').value,
        bairro: document.getElementById('bairro').value,
        cidade_id: document.getElementById('cidade_id').value,
        descritivo: document.getElementById('descritivo').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let enderecosService = new enderecoService();
      let response = await enderecosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
