<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Pessoas</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="../index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormPessoas></FormPessoas>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormPessoas from "@/views/pessoas/FormPessoas.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import pessoaService from "@/services/pessoa.service";

export default {
  name: "EditPessoas",
  components: {LayoutPage, ButtonWidget, FormPessoas},
  methods: {
    async edit(id) {
      let pessoasService = new pessoaService();
      let response = await pessoasService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('email').value = response.data.email;
      document.getElementById('cnpj').value = response.data.cnpj;
      document.getElementById('cpf').value = response.data.cpf;
      document.getElementById('tipo').value = response.data.tipo;
      document.getElementById('telefone_principal').value = response.data.telefone_principal;
      document.getElementById('telefone_secundario').value = response.data.telefone_secundario;
      document.getElementById('email_principal').value = response.data.email_principal;
      document.getElementById('email_secundario').value = response.data.email_secundario;
      document.getElementById('ativo').value = response.data.ativo;
      document.getElementById('grupo_id').value = response.data.grupo_id;
      document.getElementById('categoria_id').value = response.data.categoria_id;

    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        email: document.getElementById('email').value,
        cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),
        cpf: String(document.getElementById('cpf').value).replace(/[^a-zA-Z0-9]/g, ''),
        tipo: document.getElementById('tipo').value,
        telefone_principal: document.getElementById('telefone_principal').value,
        telefone_secundario: document.getElementById('telefone_secundario').value,
        email_principal: document.getElementById('email_principal').value,
        email_secundario: document.getElementById('email_secundario').value,
        ativo: document.getElementById('ativo').value,
        grupo_id: document.getElementById('grupo_id').value,
        categoria_id: document.getElementById('categoria_id').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let pessoasService = new pessoaService();
      let response = await pessoasService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
