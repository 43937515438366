<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Unidades</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormUnidades></FormUnidades>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormUnidades from "@/views/unidades/FormUnidades.vue";
import unidadeService from "@/services/unidade.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateUnidades",
  components: {FormUnidades, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        codigo_unidade: document.getElementById('codigo_unidade').value,
        cnpj_unidade: document.getElementById('cnpj_unidade').value,
        cpf_titular: document.getElementById('cpf_titular').value,
        ie_unidade: document.getElementById('ie_unidade').value,
        rg_titular: document.getElementById('rg_titular').value,
        telefone_principal: document.getElementById('telefone_principal').value,
        telefone_secundario: document.getElementById('telefone_secundario').value,
        email_principal: document.getElementById('email_principal').value,
        email_secundario: document.getElementById('email_secundario').value,
        tipo: document.getElementById('tipo').value,
        ativo: document.getElementById('ativo').value,
        grupo_id: document.getElementById('grupo_id').value,
        categoria_id: document.getElementById('categoria_id').value,
        mercadopago_access_token: document.getElementById('mercadopago_access_token').value,
        mercadopago_public_token: document.getElementById('mercadopago_public_token').value,



      }
      let unidadesService = new unidadeService();
      let response = await unidadesService.store(dataForm);

      if (response.data?.id) {
        location.href = '/unidades/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
