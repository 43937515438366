<template>
  <input-form class-list="col-12" type="string" label="Logradouro " value="" name="logradouro"/>
  <input-form class-list="col-12" type="string" label="Número " value="" name="numero"/>
  <input-form class-list="col-12" type="string" label="Complemento  " value="" name="complemento"/>
  <input-form class-list="col-12" type="string" label="Bairro " value="" name="bairro"/>
  <input-form placeholder="Selecione Cidade " class-list="col-12" type="select2" url="/api/cidades/list" label="Cidade "
              value="" name="cidade_id"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormEnderecos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
