<template>
  <input-form class-list="col-12" type="decimal" label="Valor " value="" name="valor"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select"
              :items="[{id:'dinheiro',message:'dinheiro',},{id:'cartao_credito',message:'cartão crédito',},{id:'cartao_debito',message:'cartão débito',},{id:'transferencia_bancaria',message:'transferência bancária',},{id:'boleto',message:'boleto',},{id:'pix',message:'pix',},{id:'cheque',message:'cheque',},]"
              label="Tipo " value="" name="tipo"/>
  <input-form placeholder="Selecione Vencimento " class-list="col-12" type="date" label="Vencimento" value=""
              name="vencimento"/>
  <input-form placeholder="Selecione Pagamento"
              class-list="col-12"
              type="select"
              :items="
                  [
                  {id:'new',message:'Novo',},
                  {id:'pending',message:'Pendente',},
                  {id:'authorized',message:'Autorizado',},
                  {id:'approved',message:'Aprovado',},
                  {id:'in_process',message:'Em processo',},
                  {id:'in_mediation',message:'Em mediação',},
                  {id:'cancelled',message:'Cancelado',},
                  {id:'refunded',message:'Devolvido',},
                  {id:'charged_back',message:'Cobrado de volta',},
              ]"
              label="Estado Pagamento"
              value=""
              name="payment_status" />


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormContaPagamentos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>