<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="blob" label="Anexo  " value="" name="anexo"/>
  <input-form class-list="col-12" type="tinyint" label="Disponível Ecommerce  " value="" name="disponivel_ecommerce"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormAnexos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
