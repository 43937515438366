import { createRouter, createWebHistory } from "vue-router";
import LoginForm from "@/views/auth/LoginForm.vue";
import notFound from "@/views/errors/NotFound.vue";
import Middleware from "@/services/Middleware";

import createUsers from "@/views/users/CreateUsers.vue";
import editUsers from "@/views/users/EditUsers.vue";
import createAnexos from "@/views/anexos/CreateAnexos.vue";
import indexAnexos from "@/views/anexos/IndexAnexos.vue";
import editAnexos from "@/views/anexos/EditAnexos.vue";
import DashBoardPage from "@/views/pages/DashBoardPage.vue";
import IndexCategorias from "@/views/categorias/IndexCategorias.vue";
import createCategorias from "@/views/categorias/CreateCategorias.vue";
import editCategorias from "@/views/categorias/EditCategorias.vue";
import indexGrupos from "@/views/grupos/IndexGrupos.vue";
import createGrupos from "@/views/grupos/CreateGrupos.vue";
import editGrupos from "@/views/grupos/EditGrupos.vue";
import createUnidades from "@/views/unidades/CreateUnidades.vue";
import indexUnidades from "@/views/unidades/IndexUnidades.vue";
import editUnidades from "@/views/unidades/EditUnidades.vue";
import createPessoas from "@/views/pessoas/CreatePessoas.vue";
import indexPessoas from "@/views/pessoas/IndexPessoas.vue";
import editPessoas from "@/views/pessoas/EditPessoas.vue";
import createCidades from "@/views/cidades/CreateCidades.vue";
import indexCidades from "@/views/cidades/IndexCidades.vue";
import editCidades from "@/views/cidades/EditCidades.vue";
import createProdutos from "@/views/produtos/CreateProdutos.vue";
import indexProdutos from "@/views/produtos/IndexProdutos.vue";
import editProdutos from "@/views/produtos/EditProdutos.vue";
import indexUsers from "@/views/users/IndexUsers.vue";
import createRelatorios from "@/views/relatorios/CreateRelatorios.vue";
import indexRelatorios from "@/views/relatorios/IndexRelatorios.vue";
import editRelatorios from "@/views/relatorios/EditRelatorios.vue";
import ViewRelatorios from "@/views/relatorios/ViewRelatorios.vue";
import createTabelaPrecos from "@/views/tabela_precos/CreateTabelaPrecos.vue";
import indexTabelaPrecos from "@/views/tabela_precos/IndexTabelaPrecos.vue";
import editTabelaPrecos from "@/views/tabela_precos/EditTabelaPrecos.vue";
import createTabelaPrecosUnidades from "@/views/tabela_precos_unidades/CreateTabelaPrecosUnidades.vue";
import indexTabelaPrecosUnidades from "@/views/tabela_precos_unidades/IndexTabelaPrecosUnidades.vue";

import createTabelaPrecosProdutos from "@/views/tabela_precos_produtos/CreateTabelaPrecosProdutos.vue";
import indexTabelaPrecosProdutos from "@/views/tabela_precos_produtos/IndexTabelaPrecosProdutos.vue";
import editTabelaPrecosProdutos from "@/views/tabela_precos_produtos/EditTabelaPrecosProdutos.vue";
import indexMovimentos from "@/views/movimentos/IndexMovimentos.vue";
import createMovimentos from "@/views/movimentos/CreateMovimentos.vue";
import editMovimentos from "@/views/movimentos/EditMovimentos.vue";
import createRelatorioUnidades from "@/views/relatorio_unidades/CreateRelatorioUnidades.vue";
import indexRelatorioUnidades from "@/views/relatorio_unidades/IndexRelatorioUnidades.vue";
import editRelatorioUnidades from "@/views/relatorio_unidades/EditRelatorioUnidades.vue";
import indexContas from "@/views/contas/IndexContas.vue";
import createContas from "@/views/contas/CreateContas.vue";
import editContas from "@/views/contas/EditContas.vue";
import createEnderecos from "@/views/enderecos/CreateEnderecos.vue";
import indexEnderecos from "@/views/enderecos/IndexEnderecos.vue";
import editEnderecos from "@/views/enderecos/EditEnderecos.vue";
import indexContaPagamentos from "@/views/conta_pagamentos/IndexContaPagamentos.vue";
import createContaPagamentos from "@/views/conta_pagamentos/CreateContaPagamentos.vue";
import editContaPagamentos from "@/views/conta_pagamentos/EditContaPagamentos.vue";
import PaymentContaPagamentos from "@/views/conta_pagamentos/PaymentContaPagamentos.vue";


const routes = [

    // categorias
    {
        path: '/categorias/:table/index',
        name: 'IndexCategorias',
        component: IndexCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/categorias/:table/create',
        name: 'CreateCategorias',
        component: createCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/categorias/:table/edit/:id',
        name: 'editCategorias',
        component: editCategorias,
        meta: {
            auth: true
        }
    },
    //grupos
    {
        path: '/grupos/:table/index',
        name: 'indexGrupos',
        component: indexGrupos,
        meta: {
            auth: true
        }
    },
    {
        path: '/grupos/:table/create',
        name: 'createGrupos',
        component: createGrupos,
        meta: {
            auth: true
        }
    },
    {
        path: '/grupos/:table/edit/:id',
        name: 'editGrupos',
        component: editGrupos,
        meta: {
            auth: true
        }
    },
    //unidadades
    {
        path: '/unidades/create',
        name: 'createUnidades',
        component: createUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/unidades/index',
        name: 'indexUnidades',
        component: indexUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/unidades/edit/:id',
        name: 'editUnidades',
        component: editUnidades,
        meta: {
            auth: true
        }
    },
    //pessoas
    {
        path: '/pessoas/create',
        name: 'createPessoas',
        component: createPessoas,
        meta: {
            auth: true
        }
    },
    {
        path: '/pessoas/index',
        name: 'indexPessoas',
        component: indexPessoas,
        meta: {
            auth: true
        }
    },
    {
        path: '/pessoas/edit/:id',
        name: 'editPessoas',
        component: editPessoas,
        meta: {
            auth: true
        }
    },
    //cidades
    {
        path: '/cidades/create',
        name: 'createCidades',
        component: createCidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/cidades/index',
        name: 'indexCidades',
        component: indexCidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/cidades/edit/:id',
        name: 'editCidades',
        component: editCidades,
        meta: {
            auth: true
        }
    },
    //produtos
    {
        path: '/produtos/create',
        name: 'createProdutos',
        component: createProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/produtos/index',
        name: 'indexProdutos',
        component: indexProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/produtos/edit/:id',
        name: 'editProdutos',
        component: editProdutos,
        meta: {
            auth: true
        }
    },
    //users
    {
        path: '/users/create',
        name: 'createUsers',
        component: createUsers,
        meta: {
            auth: true
        }
    },
    {
        path: '/users/index',
        name: 'indexUsers',
        component: indexUsers,
        meta: {
            auth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'editUsers',
        component: editUsers,
        meta: {
            auth: true
        }
    },
    //tabela_precos
    {
        path: '/tabela_precos/create',
        name: 'createTabelaPrecos',
        component: createTabelaPrecos,
        meta: {
            auth: true
        }
    },
    {
        path: '/tabela_precos/index',
        name: 'indexTabelaPrecos',
        component: indexTabelaPrecos,
        meta: {
            auth: true
        }
    },
    {
        path: '/tabela_precos/edit/:id',
        name: 'editTabelaPrecos',
        component: editTabelaPrecos,
        meta: {
            auth: true
        }
    },
    //tabela_precos_produtos
    {
        path: '/tabela_precos_produtos/create/:id',
        name: 'createTabelaPrecosProdutos',
        component: createTabelaPrecosProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/tabela_precos_produtos/index/:id',
        name: 'indexTabelaPrecosProdutos',
        component: indexTabelaPrecosProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/tabela_precos_produtos/edit/:id',
        name: 'editTabelaPrecosProdutos',
        component: editTabelaPrecosProdutos,
        meta: {
            auth: true
        }
    },
    //tabela_precos_unidades
    {
        path: '/tabela_precos_unidades/create/:id',
        name: 'createTabelaPrecosUnidades',
        component: createTabelaPrecosUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/tabela_precos_unidades/index/:id',
        name: 'indexTabelaPrecosUnidades',
        component: indexTabelaPrecosUnidades,
        meta: {
            auth: true
        }
    },
    //relatorios
    {
        path: '/relatorios/create',
        name: 'createRelatorios',
        component: createRelatorios,
        meta: {
            auth: true
        }
    },
    {
        path: '/relatorios/index',
        name: 'indexRelatorios',
        component: indexRelatorios,
        meta: {
            auth: true
        }
    },
    {
        path: '/relatorios/edit/:id',
        name: 'editRelatorios',
        component: editRelatorios,
        meta: {
            auth: true
        }
    },
    {
        path: '/relatorios/view/:id',
        name: 'viewRelatorios',
        component: ViewRelatorios,
        meta: {
            auth: true
        }
    },
    //relatorio_unidades
    {
        path: '/relatorio_unidades/create',
        name: 'createRelatorioUnidades',
        component: createRelatorioUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/relatorio_unidades/index',
        name: 'relatorio_unidades',
        component: indexRelatorioUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/relatorio_unidades/edit/:id',
        name: 'editRelatorioUnidades',
        component: editRelatorioUnidades,
        meta: {
            auth: true
        }
    },
    //enderecos
    {
        path: '/enderecos/:table/create/:pessoa_id',
        name: 'createEnderecos',
        component: createEnderecos,
        meta: {
            auth: true
        }
    },
    {
        path: '/enderecos/:table/index/:pessoa_id',
        name: 'indexEnderecos',
        component: indexEnderecos,
        meta: {
            auth: true
        }
    },
    {
        path: '/enderecos/edit/:id',
        name: 'editEnderecos',
        component: editEnderecos,
        meta: {
            auth: true
        }
    },
    // anexos
    {
        path: '/anexos/create/:tipo/:model_id',
        name: 'createAnexos',
        component: createAnexos,
        meta: {
            auth: true
        }
    },
    {
        path: '/anexos/index/:tipo/:model_id',
        name: 'indexAnexos',
        component: indexAnexos,
        meta: {
            auth: true
        }
    },
    {
        path: '/anexos/edit/:id',
        name: 'editAnexos',
        component: editAnexos,
        meta: {
            auth: true
        }
    },
    // movimentos
    {
        path: '/movimentos/index/:tipo',
        name: 'indexMovimentos',
        component: indexMovimentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/movimentos/create/:tipo',
        name: 'createMovimentos',
        component: createMovimentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/movimentos/edit/:id',
        name: 'editMovimentos',
        component: editMovimentos,
        meta: {
            auth: true
        }
    },
    // financeiros
    {
        path: '/contas/index/:tipo',
        name: 'indexContas',
        component: indexContas,
        meta: {
            auth: true
        }
    },
    {
        path: '/contas/create/:tipo',
        name: 'createContas',
        component: createContas,
        meta: {
            auth: true
        }
    },
    {
        path: '/contas/edit/:id',
        name: 'editContas',
        component: editContas,
        meta: {
            auth: true
        }
    },
    // pagamentos
    {
        path: '/conta_pagamentos/index/:conta_id',
        name: 'indexContaPagamentos',
        component: indexContaPagamentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/conta_pagamentos/create/:conta_id',
        name: 'createContaPagamentos',
        component: createContaPagamentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/conta_pagamentos/edit/:id',
        name: 'editContaPagamentos',
        component: editContaPagamentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/conta_pagamentos/pay/:id',
        name: 'PaymentContaPagamentos',
        component: PaymentContaPagamentos,
        meta: {
            auth: true
        }
    },
    //auth
    {
        path: '/404',
        component: notFound
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/painel',
        name: 'DashBoardPage',
        component: DashBoardPage,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'LoginForm',
        component: LoginForm,
        meta: {
            auth: false
        }

    },

];
const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach((to) => {
    let middleware = new Middleware();
    if (!middleware.logout(to)) {
        middleware.routeExists(to);
        middleware.validateHash(to);

    }


})
router.afterEach((to) => {
    let middleware = new Middleware();
    middleware.setRegisterLastRouteBeforeLogin();
    middleware.userPermissions(to);
    middleware.finishLoading();
    middleware.hideRevendedor();
});
export default router;