<template>
    <layout-page>
        <div class="row">
            <div class="col-12 ps-4 pt-3 ">
                <div class="float-start">
                    <h5>Editar Relatorio para Unidades</h5>
                </div>
                <div class="float-end">
                    <button-widget cor="azul" href="../index" tamanho="M">
                        Voltar
                    </button-widget>
                </div>
            </div>

        </div>
        <div class="row">
            <FormRelatorioUnidades></FormRelatorioUnidades>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </layout-page>

</template>

<script>
import FormRelatorioUnidades from "@/views/relatorio_unidades/FormRelatorioUnidades.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import relatorio_unidadeService from "@/services/relatorio_unidade.service";

export default {
    name: "EditRelatorioUnidades",
    components: {LayoutPage, ButtonWidget, FormRelatorioUnidades},
    methods:{
        async edit(id){
            let relatorio_unidadesService = new relatorio_unidadeService();
            let response = await relatorio_unidadesService.view(id);
        document.getElementById('unidade_id').value = response.data.unidade_id;
document.getElementById('relatorio_id').value = response.data.relatorio_id;

        },
        async sendForm(){
            let dataForm = {
        unidade_id: document.getElementById('unidade_id').value,
deleted_at: document.getElementById('relatorio_id').value,

            _method:'PUT'

        }
            if(!dataForm.parent_id){
                delete dataForm.parent_id
            }
            let id = this.$route.params.id;
            let relatorio_unidadesService = new relatorio_unidadeService();
            let response = await relatorio_unidadesService.update(dataForm,id);
            if(response.data?.id){
                toastr.success('Salvo com sucesso')
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    },
    created() {
        this.edit(this.$route.params.id)
    }
}
</script>

<style scoped>

</style>
