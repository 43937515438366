<template>

  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-center">
          <img v-if="!imgQrCode" src="@/assets/no-image.png" alt="">
          <img width="300"  :src="'data:image/png;base64,'+imgQrCode" alt="">
        </div>
        <div class="col-10 text-center">
         <input type="text" v-model="QrCode" class="form-control">
        </div>
        <div class="col-2 text-center">
          <button class="btn btn-primary text-white" @click="copyCodigo">
            <i v-if="!copyClipboard" class="bi bi-clipboard"></i>
            <i v-if="copyClipboard" class="bi bi-clipboard-check"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import conta_pagamentoService from "@/services/conta_pagamento.service";
import PagamentosService from "@/services/pagamento.service";

export default {
  name: "PixContaPagamentos",
  components: {},
  data() {
    return {
      tipo: null,
      conta_id: null,
      imgQrCode: false,
      QrCode: '',
      copyClipboard: false,
    }
  },
  methods: {
    async edit(id) {
      let conta_pagamentosService = new conta_pagamentoService();
      let response = await conta_pagamentosService.view(id);

      this.tipo = response.data.tipo;
      this.conta_id = response.data.conta_id;


    },
    copyCodigo(){
      this.copyClipboard = true;

      // Copia para a área de transferência
      navigator.clipboard.writeText(this.QrCode).then(() => {
        this.copyClipboard = true;

        // Remove o feedback após 4 segundos
        setTimeout(() => {
          this.copyClipboard = false;
        }, 4000);
      }).catch(err => {
        console.error("Erro ao copiar o código:", err);
      });

    }
  },
  created() {
    this.edit(this.$route.params.id)
  },
  async  mounted() {
    let pagamentosService = new PagamentosService();
    let response  = await pagamentosService.view(this.$route.params.id);
    this.imgQrCode = response.data.point_of_interaction.transaction_data.qr_code_base64
    this.QrCode = response.data.point_of_interaction.transaction_data.qr_code
  }
}
</script>

<style scoped>
.bi-clipboard::before {
  color: white !important;
}
.bi-clipboard-check::before {
  color: white !important;
}
</style>
