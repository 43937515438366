<template>
      <input-form placeholder="Selecione Unidade " class-list="col-12" type="select2" url="/api/unidades/list" label="Unidade " value="" name="unidade_id"/>
      <input-form placeholder="Selecione Relçatório " class-list="col-12" type="select2" url="/api/relatorios/list" label="Relatório " value="" name="relatorio_id"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormRelatorioUnidades",
    components: {InputForm},
    data() {
        return {}
    },
    methods:{},

created() {}
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
