<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Produtos</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="../index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormProdutos></FormProdutos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormProdutos from "@/views/produtos/FormProdutos.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import produtoService from "@/services/produto.service";

export default {
  name: "EditProdutos",
  components: {LayoutPage, ButtonWidget, FormProdutos},
  methods: {
    async edit(id) {
      let produtosService = new produtoService();
      let response = await produtosService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('ativo').value = response.data.ativo;
      document.getElementById('categoria_id').value = response.data.categoria_id;
      document.getElementById('grupo_id').value = response.data.grupo_id;

    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        ativo: document.getElementById('ativo').value,
        categoria_id: document.getElementById('categoria_id').value,
        grupo_id: document.getElementById('grupo_id').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let produtosService = new produtoService();
      let response = await produtosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
