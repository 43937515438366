<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Pagamento</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'/conta_pagamentos/index/'+conta_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12" v-if="tipo==='pix'">
        <pix-conta-pagamentos></pix-conta-pagamentos>
      </div>
      <div class="col-12" v-if="tipo==='cartao_credito'">
        <card-conta-pagamentos></card-conta-pagamentos>
      </div>
      <div class="col-12" v-if="tipo==='boleto'">
       <boleto-conta-pagamentos></boleto-conta-pagamentos>
      </div>
    </div>
  </layout-page>

</template>

<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import conta_pagamentoService from "@/services/conta_pagamento.service";
import PixContaPagamentos from "@/views/conta_pagamentos/PixContaPagamentos.vue";
import CardContaPagamentos from "@/views/conta_pagamentos/CardContaPagamentos.vue";
import BoletoContaPagamentos from "@/views/conta_pagamentos/BoletoContaPagamentos.vue";

export default {
  name: "PaymentContaPagamentos",
  components: {BoletoContaPagamentos, CardContaPagamentos, PixContaPagamentos, LayoutPage, ButtonWidget},
  data() {
    return {
      tipo: null,
      conta_id: null,
    }
  },
  methods: {
    async edit(id) {
      let conta_pagamentosService = new conta_pagamentoService();
      let response = await conta_pagamentosService.view(id);

      this.tipo = response.data.tipo;
      this.conta_id = response.data.conta_id;


    },
  },
  created() {
    this.edit(this.$route.params.id)
  },

}
</script>

<style scoped>

</style>
