<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Pessoas</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormPessoas></FormPessoas>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormPessoas from "@/views/pessoas/FormPessoas.vue";
import pessoaService from "@/services/pessoa.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreatePessoas",
  components: {FormPessoas, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        email: document.getElementById('email').value,
        cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),
        cpf: String(document.getElementById('cpf').value).replace(/[^a-zA-Z0-9]/g, ''),
        tipo: document.getElementById('tipo').value,
        telefone_principal: document.getElementById('telefone_principal').value,
        telefone_secundario: document.getElementById('telefone_secundario').value,
        email_principal: document.getElementById('email_principal').value,
        email_secundario: document.getElementById('email_secundario').value,
        ativo: document.getElementById('ativo').value,
        grupo_id: document.getElementById('grupo_id').value,
        categoria_id: document.getElementById('categoria_id').value,


      }
      let pessoasService = new pessoaService();
      let response = await pessoasService.store(dataForm);

      if (response.data?.id) {
        location.href = '/pessoas/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
