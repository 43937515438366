<template>

  <div class="row">
    <div class="col-12 pt-2">
      <iframe :src="urlBoleto" class="iframe-display"></iframe>
    </div>
  </div>

</template>

<script>
import PagamentosService from "@/services/pagamento.service";

export default {
  name: "BoletoContaPagamentos",
  components: {},
  data() {
    return {
      urlBoleto:''
    }
  },
  methods: {

  },

  async  mounted() {
    let pagamentosService = new PagamentosService();
    let response  = await pagamentosService.view(this.$route.params.id);
    this.urlBoleto = response.data.transaction_details.external_resource_url;
  }
}
</script>

<style scoped>
.iframe-display{
  width: 100%;
  height: 450px;
}
</style>
