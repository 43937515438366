<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Anexos {{ tipo }}</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end ms-2">

              <button-widget cor="azul" :href="'/anexos/create/'+tipo+'/'+model_id" tamanho="M">
                Adicionar
              </button-widget>
            </div>
            <div class="float-end">
              <button @click="voltarPagina()" class="btn btn-system">
                Voltar
              </button>

            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/anexos/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                                        <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                                            <i class="bi bi-trash2-fill"></i>
                                             Apagar

                                        </span>
                  </li>
                  <li>
                                        <span class="dropdown-item cursor-pointer" @click="downloadAnexo(row.anexo)">
                                          <i class="bi bi-cloud-arrow-down"></i>
                                             Download

                                        </span>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Nome : </strong>{{ row.nome }}</div>
              <div class="col-12"><strong>Disponível no e-commerce : </strong>{{ row.disponivel_ecommerce?'Sim':'Não' }}</div>
            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import anexoService from "@/services/anexo.service";

export default {
  name: "IndexAnexos",
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      tipo: null,
      model_id: null,
    }
  },
  methods: {
    async list() {

      let anexosService = new anexoService();
      let dataRow = await anexosService.list(this.search, this.model_id,this.tipo);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let anexosService = new anexoService();
      let dataRow = await anexosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    downloadAnexo(anexoBase64) {
      // Extrai o tipo de arquivo do anexo Base64
      const [tipo, base64] = anexoBase64.split(',');
      const extensao = this.getExtensionFromMimeType(tipo);
      const link = document.createElement('a');

      // Cria um blob a partir da string Base64
      const blob = this.b64toBlob(base64, tipo);

      // Cria um URL para o blob
      const url = URL.createObjectURL(blob);

      // Define o nome do arquivo com a extensão
      link.href = url;
      link.download = `anexo.${extensao}`; // Nome do arquivo com extensão
      document.body.appendChild(link);
      link.click();

      // Limpa o URL do blob e remove o link
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    getExtensionFromMimeType(mimeType) {
    let [,extentions] = String(mimeType).split('/');
    return extentions.substring(0,3);

    },
    voltarPagina(){
      history.back()
    }

  },
  created() {
    this.model_id = this.$route.params.model_id
    this.tipo = this.$route.params.tipo
    this.list();
  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
