<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="string" label="Código de Unidade  " value="" name="codigo_unidade"/>
  <input-form class-list="col-12" type="mask" mask="00.000.000/0000-00" label="CNPJ da Unidade  " value=""
              name="cnpj_unidade"/>
  <input-form class-list="col-12" type="mask" mask="000.000.000-00" label="CPF do Titular  " value=""
              name="cpf_titular"/>
  <input-form class-list="col-12" type="string" label="IE Unidade  " value="" name="ie_unidade"/>
  <input-form class-list="col-12" type="string" label="RG Titular  " value="" name="rg_titular"/>
  <input-form class-list="col-12" type="mask" mask="(000) 00000-0000" label="Telefone Principal  " value=""
              name="telefone_principal"/>
  <input-form class-list="col-12" type="mask" mask="(000) 00000-0000" label="Telefone Secundário  " value=""
              name="telefone_secundario"/>
  <input-form class-list="col-12" type="string" label="E-mail Principal  " value="" name="email_principal"/>
  <input-form class-list="col-12" type="string" label="E-mail Secundário  " value="" name="email_secundario"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select"
              :items="[{id:'revendedor',message:'revendedor',},{id:'gestor',message:'gestor',},]"
              label="Tipo " value="" name="tipo"/>
  <input-form placeholder="Selecione categoria" url="/api/categorias/list/unidades" class-list="col-12" type="select2"
              label="Categoria" value="" name="categoria_id"/>
  <input-form placeholder="Selecione  grupo" url="/api/grupos/list/unidades" class-list="col-12" type="select2"
              label="Grupo" value="" name="grupo_id"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>

  <input-form class-list="col-12" type="string" label="Token de Acesso " value="" name="mercadopago_access_token"/>
  <input-form class-list="col-12" type="string" label="Token Publico " value="" name="mercadopago_public_token"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormUnidades",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
