<template>
    <layout-page>
        <div class="row">
            <div class="col-12 ps-4 pt-3 ">
                <div class="float-start">
                    <h5>Adicionar Cidades</h5>
                </div>
                <div class="float-end">
                    <button-widget cor="azul" href="./index" tamanho="M">
                        Voltar
                    </button-widget>
                </div>
            </div>

        </div>
        <div class="row">
            <FormCidades></FormCidades>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormCidades from "@/views/cidades/FormCidades.vue";
import cidadeService from "@/services/cidade.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateCidades",
    components: {FormCidades, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        codigo: document.getElementById('codigo').value,
nome: document.getElementById('nome').value,
uf: document.getElementById('uf').value,
grupo_id: document.getElementById('grupo_id').value,
categoria_id: document.getElementById('categoria_id').value,


        }
            let cidadesService = new cidadeService();
            let response = await cidadesService.store(dataForm);

            if(response.data?.id){
                location.href = '/cidades/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>
