<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Relatorios</h5>
        </div>
        <div class="float-end no-print">
          <button-widget cor="azul" href="../index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div v-if="displayTable" class="row">
      <div class="col-12 text-center">
        <div class="btn-group no-print">
          <button type="button" class="btn btn-system" @click="printTela">
            <i class="bi bi-printer"></i>
          </button>
          <button type="button" class="btn btn-system " @click="csvTela">
            <i class="bi bi-filetype-csv"></i>
          </button>
          <button type="button" class="btn btn-system " @click="pdfTela">
            <i class="bi bi-filetype-pdf"></i>
          </button>
        </div>

      </div>
      <div class="col-12 p-5">
        <table id="report" class="table">

          <tbody>
            <tr>
              <th class="border p-2" v-for="(column, key) in columns" :key="column">
                {{ key }}
              </th>
            </tr>
            <tr v-for="row in rows" :key="row">
              <td class="border" v-for="column in row" :key="column">{{ column }}</td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
    <div class="row">
      <div class="col-12">

      </div>
    </div>
    <modal-widget>
      <div class="row">
        <div class="col-12">
          <h3>Filtro</h3>
        </div>
      </div>
      <div class="row">
        <div v-for="column in filter"  :key="column" class="col-12 pt-2">
          <label :for="column.replaceAll(':','')">{{column.replaceAll(':','')}}</label>
          <input :id="column.replaceAll(':','')" type="text" class="form-control column">
        </div>
        <div class="col-12 pt-2">
          <button class="btn btn-info" @click="pesquisarRelatorio()">Pesquisar</button>
        </div>
      </div>
    </modal-widget>
  </layout-page>

</template>

<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import relatorioService from "@/services/relatorio.service";
import { jsPDF } from "jspdf";
import "jspdf-autotable"
import ModalWidget from "@/components/widget/modalWidget.vue";


export default {
  name: "ViewRelatorios",
  components: {ModalWidget, LayoutPage, ButtonWidget },
  data() {
    return {
      columns: null,
      rows: null,
      filter:null,
      displayTable:false
    }
  },
  methods: {
    async edit(id) {
      let relatoriosService = new relatorioService();
      let payload = {};
      let columns  = document.getElementsByClassName('column');
      for (const column of columns) {
       payload[column.id]=column.value
      }
      console.log(payload)

      let response = await relatoriosService.consulta(id,payload);

      this.columns = response?.data?.data[0];
      this.rows = response?.data?.data;

      this.displayTable = true;

      document.getElementsByClassName('modal-widget')[0].classList.add('d-none');

    },
    printTela() {
      let noPrints = document.getElementsByClassName('no-print');
      console.log(noPrints)
      for (const noPrint of noPrints) {
        noPrint.classList.add('d-none');


      }
      document.getElementById('sidebarMenu').classList.remove('bg-success');
      document.getElementById('mainContent').classList.remove('content');
      window.print();
      document.getElementById('mainContent').classList.add('content');
      document.getElementById('sidebarMenu').classList.add('bg-success');
      for (const noPrint of noPrints) {
        noPrint.classList.remove('d-none');

      }
    },
    csvTela() {
      const tabela = document.getElementById('report');
      const linhas = Array.from(tabela.rows);
      console.log(linhas)

      const csvContent = linhas.map(row => {
        const colunas = Array.from(row.cells).map(cell => cell.innerText);
        return colunas.join(',');
      }).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'dados.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pdfTela() {
      const doc = new jsPDF();

      // Dados da tabela
      const colunas = Object.keys(this.columns);

      let dados = [];
      Object.values(this.rows).forEach(value => {

        dados.push(Object.values(value));
      });



      // Adiciona título
      doc.text("Relatório", 14, 10);

      // Adiciona a tabela
      doc.autoTable({
        head: [colunas],
        body: dados,
        startY: 20, // Posição inicial da tabela
      });

      // Salva o PDF
      doc.save("tabela.pdf");
    },
    async mountSearchForm(id){
      let relatoriosService = new relatorioService();
      let filter = await relatoriosService.filtro(id);
      this.filter = filter?.data[0];
      if(this.filter.length==0){
        return false;
      }
      document.getElementsByClassName('modal-widget')[0].classList.remove('d-none');
    },
    pesquisarRelatorio(){
      this.edit(this.$route.params.id)
    }
  },
  created() {

  },
  mounted() {
    this.mountSearchForm(this.$route.params.id);
  }
}
</script>

<style scoped>
.bi {

  color: white !important;
}
</style>
