<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Endereços</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'/enderecos/'+table+'/index/'+this.pessoa_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormEnderecos></FormEnderecos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormEnderecos from "@/views/enderecos/FormEnderecos.vue";
import enderecoService from "@/services/endereco.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateEnderecos",
  components: {FormEnderecos, LayoutPage, ButtonWidget},
  data(){
    return{
      pessoa_id: null,
      table: null,
    }
  },
  methods: {
    async sendForm() {
      let dataForm = {
        logradouro: document.getElementById('logradouro').value,
        numero: document.getElementById('numero').value,
        complemento: document.getElementById('complemento').value,
        bairro: document.getElementById('bairro').value,
        cidade_id: document.getElementById('cidade_id').value,
        descritivo: document.getElementById('descritivo').value,
        tipo:this.table,
        model_id: this.pessoa_id,
      }
      let enderecosService = new enderecoService();
      let response = await enderecosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/enderecos/'+this.table+'/index/'+this.pessoa_id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {
    this.pessoa_id = this.$route.params.pessoa_id;
    this.table = this.$route.params.table;
  }
}
</script>
<style scoped>
</style>
