<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Anexos</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'/anexos/index/'+tipo+'/'+model_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormAnexos></FormAnexos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormAnexos from "@/views/anexos/FormAnexos.vue";
import anexoService from "@/services/anexo.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateAnexos",
  components: {FormAnexos, LayoutPage, ButtonWidget},
  data(){
    return {
      model_id:null,
      tipo:null,
    }
  },
  methods: {
    async sendForm() {
      let dataForm = {
        tipo: this.tipo,
        model_id: this.model_id,
        nome: document.getElementById('nome').value,
        anexo: document.getElementById('anexo').dataset.value,
        disponivel_ecommerce: document.getElementById('disponivel_ecommerce').value,
        descritivo: document.getElementById('descritivo').value,
      }
      let anexosService = new anexoService();
      let response = await anexosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/anexos/index/'+this.tipo+'/'+this.model_id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    initForm(){
      this.model_id = this.$route.params.model_id
      this.tipo = this.$route.params.tipo
    }
  },
  mounted() {
    this.initForm();

  }
}
</script>
<style scoped>
</style>
