<template>
  <nav-bar></nav-bar>

  <main class="content">
    <menu-row></menu-row>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card border bg-smoke">
              <div class="card-head p-2 border">
                <h5 class="text-center">Número de Vendas</h5>
              </div>
              <div class="card-body border">
                <h2 class="text-center">{{ numeroVendas }}</h2>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card border bg-smoke">
              <div class="card-head p-2 border">
                <h5 class="text-center">Número de Produtos</h5>
              </div>
              <div class="card-body border">
                <h2 class="text-center">{{ numeroProdutos }}</h2>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card border bg-smoke">
              <div class="card-head p-2 border">
                <h5 class="text-center">Número de Pessoas</h5>
              </div>
              <div class="card-body border">
                <h2 class="text-center">{{ numeroPessoas }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pt-5">
            <h5>Ultimas vendas</h5>
          </div>
          <div class="col-12">
            <table class="table table-responsive">
              <thead>
              <tr>
                <th>ID</th>
                <th>CLIENTE</th>
                <th>DATAS</th>
                <th>DESCONTO %</th>
                <th>TOTAL</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in rows" :key="row.id">
                <td>{{ row.id }}</td>
                <td>{{ row.pessoa_unidade }}</td>
                <td>{{ row.created }}</td>
                <td>{{ Number(row.desconto).toLocaleString('pt-BR', {minimumFractionDigits: 2}) }}</td>
                <td>{{ Number(row.total).toLocaleString('pt-BR', {minimumFractionDigits: 2}) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>
<script>

import PanelService from "@/services/panel.service";

export default {
  name: "DashBoardPage",

  components: {},
  data() {
    return {
      numeroVendas: 0,
      numeroProdutos: 0,
      numeroPessoas: 0,
      rows: null,
    }
  },
  methods: {
    async countNumeroDasboard() {
      let panelService = new PanelService();
      let response = await panelService.panelCount();
      this.numeroVendas = await response.data.numeroVendas;
      this.numeroProdutos = await response.data.numeroProdutos;
      this.numeroPessoas = await response.data.numeroPessoas;
    },
    async listVendas() {
      let panelService = new PanelService();
      let response = await panelService.listVendas();
      this.rows = response?.data;
    }
  },

  mounted() {
    this.countNumeroDasboard()
    this.listVendas()
  }

}


</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style scoped>
.bg-smoke {
  background-color: #f3f3f3;
}
</style>

<script setup>

import NavBar from "@/components/page/navBar.vue";
import MenuRow from "@/components/page/menuRow.vue";

</script>