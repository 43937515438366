<template>
  <input-form placeholder="Selecione Unidade " class-list="col-12" type="select2" url="/api/unidades/list"
              label="Unidade " value="" name="unidade_id"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormTabelaPrecosUnidades",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
