<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar categoria de {{table}}</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="backURl" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormCategorias></FormCategorias>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormCategorias from "@/views/categorias/FormCategorias.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import categoriaService from "@/services/categoria.service";

export default {
  name: "EditCategorias",
  components: {LayoutPage, ButtonWidget, FormCategorias},
  data(){
    return {
      backURl:null,
      table:null
    }
  },
  methods: {
    async edit(id) {
      let categoriasService = new categoriaService();
      let response = await categoriasService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('ativo').value = response.data.ativo;


    },
    initEdit(){
      this.backURl = '/categorias/'+this.$route.params.table+'/index';
      this.table = this.$route.params.table;
    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        tipo: this.$route.params.table,
        ativo: document.getElementById('ativo').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let categoriasService = new categoriaService();
      let response = await categoriasService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id);
    this.initEdit();
  }
}
</script>

<style scoped>

</style>
