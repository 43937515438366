<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
  <div class="revendedor_none">
    <input-form placeholder="Selecione Categoria " class-list="col-12" type="select2"
                url="/api/categorias/list/produtos"
                label="Categoria " value="" name="categoria_id"/>
    <input-form placeholder="Selecione Grupo " class-list="col-12" type="select2" url="/api/grupos/list/produtos"
                label="Grupo "
                value="" name="grupo_id"/>
  </div>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormProdutos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
