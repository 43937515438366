<template>
  <input-form class-list="col-12" type="string" label="Código " value="" name="codigo"/>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="string" label="UF" value="" name="uf"/>
  <input-form placeholder="Selecione Grupo" class-list="col-12" type="select2" url="/api/grupos/list/cidades" label="Grupo "
              value="" name="grupo_id"/>
  <input-form placeholder="Selecione Categoria " class-list="col-12" type="select2" url="/api/categorias/list/cidades"
              label="Categoria " value="" name="categoria_id"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormCidades",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
