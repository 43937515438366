<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar Usuário</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormUsers></FormUsers>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormUsers from "@/views/users/FormUsers.vue";
import userService from "@/services/user.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateUsers",
  components: {FormUsers, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {

      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        email: document.getElementById('email').value,
        password: document.getElementById('password').value,
        e_admin: document.getElementById('e_admin').value,
        e_venda: document.getElementById('e_venda').value,
        e_compra: document.getElementById('e_compra').value,
        ativo: document.getElementById('ativo').value,


      }
      let unidade_id =  document.getElementById('unidade_id');
      if(unidade_id!==null){
        dataForm.unidade_id = unidade_id.value;
      }
      let usersService = new userService();
      let response = await usersService.store(dataForm);

      if (response.data?.id) {
        location.href = '/users/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
