<template>
  <input-form placeholder="Selecione Cliente " class-list="col-6" type="select2" :url="'/api/pessoas/list/'+tipo"
              label="Cliente "
              value="" name="model_id"/>
  <input-form placeholder="Selecione Estado " class-list="col-6" type="select"
              :items="[{id:'novo',message:'novo',},{id:'andamento',message:'andamento',},{id:'cancelado',message:'cancelado',},{id:'concluido',message:'concluido',},]"
              label="Estado " value="" name="estado"/>
  <div class="row">
    <div class="col-12 mt-5 bg-smoke">
      <div class="row ">
        <input-form placeholder="Selecione Produto " class-list="col-6 produto_id" type="select2"
                    url="/api/produtos/list"
                    label="Produto " value="" name="produto_id"/>
        <div class="col-6 ">
          <label class="p-2">Tabela de Preços</label>
          <select @change="movimentoTabelaPreco()" class="form-control" name="movimentoTabela" id="movimentoTabela">

          </select>
        </div>

        <input-form class-list="col-4" type="decimal" label="Valor " value="" name="valor"/>
        <input-form class-list="col-4 mb-5" type="decimal" label="Desconto % " value="" name="desconto"/>
        <input-form class-list="col-4" type="bigint" label="Quantidade  " value="1" name="quantidade"/>
      </div>

    </div>
  </div>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import TabelaPrecosService from "@/services/tabela_preco.service";
import movimentoService from "@/services/movimento.service";


export default {
  name: "FormMovimentos",
  components: {InputForm},
  data() {
    return {
      tipo: null
    }
  },
  methods: {
    movimentoTabelaPreco() {
      const select = document.getElementById('movimentoTabela');
      const selectedOption = select.options[select.selectedIndex];

      let desconto = selectedOption.getAttribute('data-desconto');
      let valor = selectedOption.getAttribute('data-valor');

      document.getElementById('valor').value = valor;
      document.getElementById('desconto').value = desconto;

    },
    async setTipo() {
      this.tipo = this.$route.params.tipo
      if (this.tipo === undefined) {
        let movimentosService = new movimentoService();
        let response = await movimentosService.view(this.$route.params.id);
        this.tipo = response.data.tipo;
      }
    }
  },

  mounted() {

    document.querySelector('.produto_id').addEventListener('change', async () => {
      let produto_id = await document.getElementById('produto_id').value;
      let tabelaPrecosService = await new TabelaPrecosService();
      let movimentoTabelas = await tabelaPrecosService.movimentoTabela(produto_id, this.tipo);
      movimentoTabelas = movimentoTabelas?.data;
      let option = '<option selected disabled>Selecione a tabela de preço</option>';
      for (const movimentoTabela of movimentoTabelas) {

        option += '<option data-desconto="' + movimentoTabela.desconto_maximo + '" data-valor="' + movimentoTabela.valor_minimo + '" value="' + movimentoTabela.id + '">' + movimentoTabela.nome + '</option>';

      }
      document.getElementById('movimentoTabela').innerHTML = option;

    })

  },
  created() {
    this.setTipo();
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";

.bg-smoke {
  background-color: #efefef;
}
</style>
