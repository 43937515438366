<template>

  <div>
    <form>
      <div class="row">
        <div class="col-12">
          <label for="cardNumber">Número do cartão</label>
          <input class="form-control" type="text" id="cardNumber" data-checkout="cardNumber" v-model="cardNumber" />
        </div>
        <div  class="col-4">
          <label for="expirationMonth">Mês de expiração</label>
          <input class="form-control" type="text" id="expirationMonth" data-checkout="cardExpirationMonth" v-model="expirationMonth" />
        </div>
        <div  class="col-4">
          <label for="expirationYear">Ano de expiração</label>
          <input class="form-control" type="text" id="expirationYear" data-checkout="cardExpirationYear" v-model="expirationYear" />
        </div>
        <div class="col-4">
          <label for="securityCode">Código de segurança</label>
          <input class="form-control" type="text" id="securityCode" data-checkout="securityCode" v-model="securityCode" />
        </div>
        <div  class="col-4">
          <label for="cardholderName">Nome no cartão</label>
          <input class="form-control" type="text" id="cardholderName" data-checkout="cardholderName" v-model="cardholderName" />
        </div>
        <div  class="col-4">
          <label for="docType">Tipo de documento</label>
          <select class="form-control" id="docType" data-checkout="docType" v-model="docType">
            <option value="CPF">CPF</option>
            <option value="CNPJ">CNPJ</option>
          </select>
        </div>
        <div  class="col-4">
          <label for="docNumber">Número do documento</label>
          <input class="form-control" type="text" id="docNumber" data-checkout="docNumber" v-model="docNumber" />
        </div>
        <div class="col-12 pt-4">
          <button @click="submitPayment" class=" btn btn-primary" type="button">Pagar</button>
        </div>

      </div>

    </form>
  </div>
</template>

<script>
import PagamentosService from "@/services/pagamento.service";

export default {
  data() {
    return {
      cardNumber: '5502093318450936',
      expirationMonth: '04',
      expirationYear: '32',
      securityCode: '172',
      cardholderName: 'LUAN MARCOS',
      docType: 'CPF',
      docNumber: '02228071218',
      mp:null,
      mercado_pago_token:null
    };
  },
  methods: {
    async paymentInit(){
      let pagamentoService = new PagamentosService();
      let environment = await pagamentoService.environment();
      this.mercado_pago_token =  environment.data.MERCADOPAGO_PUBLIC_TOKEN


    },
    async submitPayment() {
      console.log(this.mercado_pago_token)
      console.log('a')
      this.mp = await new window.MercadoPago(this.mercado_pago_token, {
        locale: 'pt-BR',
      });
      try {
        const cardToken = await this.mp.createCardToken({
          cardNumber: this.cardNumber,
          cardholderName: this.cardholderName,
          cardExpirationMonth: this.expirationMonth,
          cardExpirationYear: this.expirationYear,
          securityCode: this.securityCode,
          identification: {
            type: this.docType,
            number: this.docNumber,
          },
        });

      let pagamento = await new PagamentosService();
     await pagamento.store(this.$route.params.id,cardToken)
      } catch (error) {
        console.error('Erro ao gerar token:', error);
      }
    },
  },
  mounted() {
    this.paymentInit()
  }
};
</script>

