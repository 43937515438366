import RequestHelper from "@/services/RequestHelper";

export default class PagamentosService {

    async view(conta_pagamento_id) {
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamentos/' + conta_pagamento_id, {});
    }
    async environment() {
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/credencial', {});
    }
    async store(conta_pagamento_id,payload){
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamentos/' + conta_pagamento_id, payload);
    }

    async findList(){
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamentos/list',{});
    }

}
