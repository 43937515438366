<template>
    <layout-page>
        <div class="row">
            <div class="col-12 ps-4 pt-3 ">
                <div class="float-start">
                    <h5>Adicionar Relatorios</h5>
                </div>
                <div class="float-end">
                    <button-widget cor="azul" href="./index" tamanho="M">
                        Voltar
                    </button-widget>
                </div>
            </div>

        </div>
        <div class="row">
            <FormRelatorios></FormRelatorios>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormRelatorios from "@/views/relatorios/FormRelatorios.vue";
import relatorioService from "@/services/relatorio.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateRelatorios",
    components: { FormRelatorios, LayoutPage, ButtonWidget },
    methods: {
        async sendForm() {
            let dataForm = {
                nome: document.getElementById('nome').value,
                sql: document.getElementById('sql').value,



            }
            let relatoriosService = new relatorioService();
            let response = await relatoriosService.store(dataForm);

            if (response.data?.id) {
                location.href = '/relatorios/index';
            } else {
                if (response.response.data?.message) {
                    toastr.error(response.response.data?.message);
                } else {
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped></style>
