<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select"
              :items="[{id:'entrada',message:'Compra',},{id:'saida',message:'Venda',},]" label="Tipo "
              value="" name="tipo"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormTabelaPrecos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
