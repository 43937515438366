<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar categoria  de {{table}}</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" href="./index" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormCategorias></FormCategorias>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormCategorias from "@/views/categorias/FormCategorias.vue";
import categoriaService from "@/services/categoria.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateCategorias",
  components: {FormCategorias, LayoutPage, ButtonWidget},
  data(){
    return {
    table:null
    }
  },
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        tipo: this.table,
        ativo: document.getElementById('ativo').value,


      }
      let categoriasService = new categoriaService();
      let response = await categoriasService.store(dataForm);

      if (response.data?.id) {
        location.href = '/categorias/'+this.$route.params.table+'/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    initCreate(){
      this.table = this.$route.params.table;
    }
  },
  mounted() {
    this.initCreate()
  }
}
</script>
<style scoped>
</style>
