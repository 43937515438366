<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Editar Produtos</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'../index/'+tabela_preco_id" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormTabelaPrecosProdutos></FormTabelaPrecosProdutos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormTabelaPrecosProdutos from "@/views/tabela_precos_produtos/FormTabelaPrecosProdutos.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import tabela_precos_produtoService from "@/services/tabela_precos_produto.service";

export default {
  name: "EditTabelaPrecosProdutos",
  components: {LayoutPage, ButtonWidget, FormTabelaPrecosProdutos},
  data() {
    return {tabela_preco_id: null}
  },
  methods: {
    async edit(id) {
      let tabela_precos_produtosService = new tabela_precos_produtoService();
      let response = await tabela_precos_produtosService.view(id);
      document.getElementById('produto_id').value = response.data.produto_id;
      document.getElementById('valor_minimo').value = response.data.valor_minimo;
      document.getElementById('desconto_maximo').value = response.data.desconto_maximo;

    },
    async sendForm() {
      let dataForm = {
        produto_id: document.getElementById('produto_id').value,
        valor_minimo: document.getElementById('valor_minimo').value,
        desconto_maximo: document.getElementById('desconto_maximo').value,
        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let tabela_precos_produtosService = new tabela_precos_produtoService();
      let response = await tabela_precos_produtosService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
    this.tabela_preco_id = this.$route.params.id;
  }
}
</script>

<style scoped>

</style>
