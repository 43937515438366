<template>
  <input-form placeholder="Selecione Cliente " class-list="col-6" type="select2" :url="tipo==='receber'?'/api/pessoas/list/saida':'/api/pessoas/list/entrada'"
              label="Cliente "
              value="" name="pessoa_id"/>
  <input-form placeholder="Selecione Tipo " class-list="col-3" type="select"
              :items="[{id:'receber',message:'receber',},{id:'pagar',message:'pagar',},]" label="Tipo " value=""
              name="tipo"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>

  <input-form placeholder="Selecione Estado " class-list="col-4" type="select"
              :items="[{id:'novo',message:'novo',},{id:'andamento',message:'andamento',},{id:'cancelado',message:'cancelado',},{id:'pago',message:'pago',},]"
              label="Estado " value="" name="estado"/>
  <input-form class-list="col-4" type="int" label="Parcelas  " value="" name="parcelas"/>
  <input-form class-list="col-4" type="decimal" label="Valor " value="" name="valor"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormContas",
  components: {InputForm},
  data() {
    return {
      tipo:null
    }
  },
  methods: {},

  created() {
    this.tipo = this.$route.params.tipo
    console.log(this.tipo)
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
