<template>
  <layout-page>
    <div class="row">
      <div class="col-12 ps-4 pt-3 ">
        <div class="float-start">
          <h5>Adicionar {{tipo==='saida'?'Vendas':'Compras' }}</h5>
        </div>
        <div class="float-end">
          <button-widget cor="azul" :href="'../index/'+tipo" tamanho="M">
            Voltar
          </button-widget>
        </div>
      </div>

    </div>
    <div class="row">
      <FormMovimentos></FormMovimentos>
      <div class="col-4">
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormMovimentos from "@/views/movimentos/FormMovimentos.vue";
import movimentoService from "@/services/movimento.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateMovimentos",
  components: {FormMovimentos, LayoutPage, ButtonWidget},
  data(){
    return {
      tipo:null
    }
  },
  methods: {
    async sendForm() {
      let dataForm = {
        descritivo: document.getElementById('descritivo').value,
        model_id: document.getElementById('model_id').value,
        estado: document.getElementById('estado').value,
        produto_id: document.getElementById('produto_id').value,
        quantidade: document.getElementById('quantidade').value,
        valor: document.getElementById('valor').value,
        desconto: document.getElementById('desconto').value,
        tipo: this.tipo,
      }
      let movimentosService = new movimentoService();
      let response = await movimentosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/movimentos/index/'+this.tipo;
      } else {
        if (response.data?.message) {
          toastr.error(response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {
    this.tipo = this.$route.params.tipo
  }
}
</script>
<style scoped>
</style>
