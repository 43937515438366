<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Endereços</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end">
              <button-widget cor="azul" :href="'/enderecos/'+table+'/create/'+pessoa_id" tamanho="M">
                Adicionar
              </button-widget>
            </div>
            <div class="float-end mx-4">
                <button class="btn btn-primary" @click="backPage()">Voltar</button>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/enderecos/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                                        <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                                            <i class="bi bi-trash2-fill"></i>
                                             Apagar

                                        </span>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Logradouro : </strong>{{ row.logradouro }}</div>
              <div class="col-12"><strong>Número : </strong>{{ row.numero }}</div>
              <div class="col-12"><strong>Complemento : </strong>{{ row.complemento }}</div>
            </td>
            <td>
              <div class="col-12"><strong>Bairro : </strong>{{ row.bairro }}</div>
              <div class="col-12"><strong>Cidade : </strong>{{ row.cidade }}</div>

            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import enderecoService from "@/services/endereco.service";

export default {
  name: "IndexEnderecos",
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      pessoa_id: null,
      table: null,
    }
  },
  methods: {
    async list() {
      this.pessoa_id = this.$route.params.pessoa_id;
      this.table = this.$route.params.table;

      let enderecosService = new enderecoService();
      let dataRow = await enderecosService.list(this.search,this.table,this.pessoa_id);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let enderecosService = new enderecoService();
      let dataRow = await enderecosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    backPage(){
      history.back()
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
